<template>
  <div class="edit">
    <template v-if="$route.query.isview == 'true'">
      <template v-if="$route.query.type == 1">
        <breadcrumb :breadcrumbList="detailBreadcrumbList1"></breadcrumb>
      </template>
      <template v-if="$route.query.type == 2">
        <breadcrumb :breadcrumbList="detailBreadcrumbList2"></breadcrumb>
      </template>
      <template v-if="$route.query.type == 4||$route.query.type==3">
        <breadcrumb :breadcrumbList="detailBreadcrumbList4"></breadcrumb>
      </template>
    </template>
    <template v-else>
      <template v-if="$route.query.type == 1">
        <breadcrumb :breadcrumbList="updateBreadcrumbList1"></breadcrumb>
      </template>
      <template v-if="$route.query.type == 2">
        <breadcrumb :breadcrumbList="updateBreadcrumbList2"></breadcrumb>
      </template>
      <template v-if="$route.query.type == 4||$route.query.type==3">
        <breadcrumb :breadcrumbList="updateBreadcrumbList4"></breadcrumb>
      </template>
    </template>

    <div class="main-content">
      <baseForm v-if="$route.query.type==1||$route.query.type==2"></baseForm>
      <baseFormShop v-if="$route.query.type==4||$route.query.type==3"></baseFormShop>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import baseForm from '../base-ui/base-form/base-form.vue'
import baseFormShop from '../base-ui/base-form/base-form-shop.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList, detailBreadcrumbList1, detailBreadcrumbList2, detailBreadcrumbList4, updateBreadcrumbList1, updateBreadcrumbList2, updateBreadcrumbList4 } from '../config'

export default defineComponent({
  props: {

  },
  components: {
    baseForm,
    Breadcrumb,
    baseFormShop
  },
  setup() {


    return {
      breadcrumbList,
      updateBreadcrumbList1,
      updateBreadcrumbList2,
      updateBreadcrumbList4,
      detailBreadcrumbList1,
      detailBreadcrumbList2,
      detailBreadcrumbList4
    }
  }
})
</script>

<style scoped lang="less">
.edit {
  .main-content {
    min-width: 1080px;
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>
